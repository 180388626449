<template>
  <!-- 素材列表页 -->
  <div class="projectList">
    <ReportRepeat></ReportRepeat>
    <!-- <Tips></Tips> -->
    <!-- 左侧模块 -->
    <div class="lock" @click="lockSort">
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-11-21_3473669.png"
        alt="" v-show="sortAbled" />
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-12-25_9033983.png"
        alt="" v-show="!sortAbled" />
      <!-- <i class="el-icon-lock" v-show="!sortAbled"></i> 
      <i class="el-icon-unlock"  v-show ="sortAbled"></i> -->
    </div>
    <div class="left-module">
      <div class="addnewProject">
        <!-- 搜索框 -->
        <div class="div-height">
          <div class="search-div30">
            <el-button @click="handleOpen('create', '添加项目')" class="left themeButton">添加项目</el-button>
          </div>
          <div class="search-div70">
            <el-select v-model="class_id" placeholder="请选择" class="input-div search-el-select" @change="getProjectList">
              <!-- 查与我有关系的所有项目 -->
              <el-option v-for="item in project_typeList" :key="item.id" :label="item.name" :value="item.id">
                <div class="selectProjectType">
                  <div class="selectProjectTypeItem">
                    <img style="width: 0.7vw; height: 0.7vw;margin-top:0.55vw" :src="item.icon" />
                  </div>
                  <div class="selectProjectTypeItem">{{ item.name }}</div>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="searchProject">
            <el-input clearable placeholder="请输入项目名称" v-model="project_name" @clear="getProjectListSearch">
              <el-button slot="append" class="el-icon-search" @click="getProjectListSearch"></el-button>
            </el-input>
          </div>
        </div>
        <!-- 项目列表 -->
        <div class="projectListBox">
          <table class="sortableTable" v-show="sortAbled">
            <tr :id="item.id" v-for="(item, index) in projectList" :key="index">
              <!-- 排序 -->
              <!-- <td class="width15">
                                <el-input
                                    v-model="item.sort_no"
                                    class="width100"
                                    @blur="sortNoUpdate(index)"
                                ></el-input>
              </td>-->
              <!-- 项目名称 -->
              <td class="td-project" @click="getcurrentActive(item.id, index)">
                <i class="el-icon-success green" v-if="item.isopen"></i>
                {{ item.name }}
              </td>
              <!-- 操作 -->
              <td class="td-handle" v-show="item.is_create">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleShare( item.id, item.name)">
                      <div class="codeItem">共享</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('rename', '项目重命名', item.id, item.name)">
                      <div class="codeItem">重命名</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="updateProjectOpenStatus(index)">
                      <div class="codeItem" v-if="item.isopen">关闭选课</div>
                      <div class="codeItem" v-else>开放选课</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('delete', '删除项目', item.id, item.name)">
                      <div class="codeItem">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </table>
          <table v-show="!sortAbled">
            <tr :id="item.id" v-for="(item, index) in projectList2" :key="index">
              <!-- 排序 -->
              <!-- <td class="width15">
                                <el-input
                                    v-model="item.sort_no"
                                    class="width100"
                                    @blur="sortNoUpdate(index)"
                                ></el-input>
              </td>-->
              <!-- 项目名称 -->
              <td class="td-project" @click="getcurrentActive(item.id, index)">
                <i class="el-icon-success green" v-if="item.isopen"></i>
                {{ item.name }}
              </td>
              <!-- 操作 -->
              <td class="td-handle" v-show="item.is_create">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleShare( item.id, item.name)">
                      <div class="codeItem">共享</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('rename', '项目重命名', item.id, item.name)">
                      <div class="codeItem">重命名</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="updateProjectOpenStatus(index)">
                      <div class="codeItem" v-if="item.isopen">关闭选课</div>
                      <div class="codeItem" v-else>开放选课</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('delete', '删除项目', item.id, item.name)">
                      <div class="codeItem">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 已删除项目 -->
      <div class="historyProject">
        <div class="delete-title">
          <div class="themeText" @click="deleteClassShow()">
            已删除项目({{ deleteNum }})
            <i class="el-icon-arrow-down" v-if="!deleteClassShowSign"></i>
            <i class="el-icon-arrow-up" v-if="deleteClassShowSign"></i>
          </div>
        </div>
        <div class="historyDelProject">
          <table v-if="deleteClassShowSign" class="brown">
            <tr :id="item.id" v-for="(item, index) in deleteProjectList" :key="index">
              <!-- 项目名称 -->
              <!-- <td class="delete-td-project" @click="getProjectInfo('del_'+item.id,index,'delete',item.status)">{{item.name}}</td> -->
              <td class="delete-td-project" @click="hideProject(item.id, index, 'delete', item.status, item.name)">
                {{ item.name }}</td>
              <!-- 操作 -->
              <td class="td-handle">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleOpen('recover', '恢复项目', item.id, item.name)">
                      <div class="codeItem">恢复</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('delete_completely', '彻底删除项目', item.id, item.name)">
                      <div class="codeItem">彻底删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- 右侧模块 -->
    <div class="div">
      <!-- 标签页 -->
      <el-tabs v-model="activeName" @tab-click="tabHandleClick">
        <!-- 设置 -->
        <el-tab-pane label="设 置" name="first" class="set">
          <div class="setDetail" v-if="projectList.length > 0">
            <div v-if="rightContentShow">
              <div class="clearFix" v-if="projectCourseList.length > 0">
                <div class="classBindListTitle">绑定课程：</div>
                <!-- <div class="classBindListBox"><div @click="jumpToGroup(item.id)" class="classBindList" v-for="(item) in projectCourseList">{{item.name}}</div></div> -->
                <div class="classBindListBox">
                  <div v-for="(item) in projectCourseList">
                    <div v-if="item.is_jump" @click="jumpToGroup(item.id)" class="classBindList">{{ item.name }}</div>
                    <div v-else class="classBindListO">{{ item.name }} </div>
                  </div>
                </div>
              </div>
              <!-- 项目名称 -->
              <div>
                <span class="setSpan">项目名称：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.name" placeholder="请输入项目名称"
                  class="setInput input-div"></el-input>
                项目创建者：{{ createUserName }}
              </div>
              <!-- 单元人数 -->
              <div>
                <span class="setSpan">单元人数：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.numbers" placeholder="请输入单元人数"
                  class="setInput input-div"></el-input>
              </div>
              <!-- 学时 -->
              <div>
                <span class="setSpan">学时：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.hours" placeholder="请输入学时"
                  class="setInput input-div"></el-input>
              </div>
              <!-- 上课地点 -->
              <!-- <div>
                                <span>上课地点：</span> 
                                <el-input v-model="projectInfo.location" placeholder="请输入上课地点" class="input-div"></el-input>
              </div>-->
              <!-- 全景预览 -->
              <div>
                <span class="setSpan">全景预览：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.yunpic" placeholder="请输入链接地址"
                  class="setInput input-div"></el-input>
              </div>
              <!-- 详细地址 -->
              <div>
                <span class="setSpan">详细地址：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.locainfo" placeholder="请输入详细地址"
                  class="setInput input-div"></el-input>
              </div>
              <!-- 地图选点 -->
              <div>
                <span class="setSpan">地图选点：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.lon" placeholder="此处显示地图选点经度"
                  class="setInput mapLat" disabled></el-input>
                <el-input :disabled="!is_create" v-model="projectInfo.lat" placeholder="此处显示地图选点纬度"
                  class="setInput mapLin" disabled></el-input>
                <el-button :disabled="!is_create" class="margin-left10 themeButton"
                  @click="baiduDialogVisible = true">地图选点</el-button>
              </div>
              <!-- 预约取消时间 -->
              <div>
                <span class="setSpan">预约取消时间(天)：</span>
                <el-input :disabled="!is_create" v-model="projectInfo.cancel_time" placeholder="请输入预约取消时间"
                  class="setInput input-div"></el-input>
              </div>
              <!-- 项目描述 -->
              <div>
                <span class="setSpan">项目描述：</span>
                <el-input :disabled="!is_create" type="textarea" autosize placeholder="请输入项目描述" v-model="projectInfo.des"
                  class="settextRear input-div"></el-input>
              </div>
              <!-- 封面图 -->
              <div>
                <span class="setSpan">封面图：</span>
                <el-upload :disabled="!is_create" class="avatar-uploader el-upload-padding" :action="uploadFilePath"
                  :show-file-list="false" :data="{ id: project_id }" :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="projectInfo.pic" :src="projectInfo.pic" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <!-- 项目负责人：下拉列表 -->
              <div>
                <span class="setSpan">项目负责人：</span>
                <el-select :disabled="!is_create || !is_show_bind" v-model="projectInfo.salve_uid" placeholder="请选择"
                  clearable class="setInput width350">
                  <el-option v-for="(item, index) in togetherList" :key="index" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
                <el-button :disabled="!is_create" class="cancelButton margin-left10"
                  @click="handleOpen('add_together', '添加同事')">添加同事</el-button>
              </div>
              <!-- 预习素材：下拉列表 -->
              <div>
                <span class="setSpan">预习素材：</span>
                <el-select :disabled="!is_create" v-model="projectInfo.article_id" placeholder="请选择" clearable
                  class="setInput width350">
                  <el-option v-for="(item, index) in articleList" :key="index" :label="item.title"
                    :value="item.id"></el-option>
                </el-select>
                <span v-if="projectInfo.article_id" class="width200">
                  <el-button :disabled="!is_create" class="cancelButton margin-left10"
                    @click="goToNewLabel('/teacher/article/articlePreview', { 'article_id': projectInfo.article_id, 'hideMenu': 1 })">预览</el-button>
                  <el-button :disabled="!is_create" class="cancelButton margin-left10"
                    @click="goToArticleEdit('/teacher/article/articleUpdate', { 'article_id': projectInfo.article_id, 'hideMenu': 1 })">编辑</el-button>
                </span>
              </div>
              <!-- 电子报告 -->

              <div>
                <span class="setSpan">报告模板：</span>
                <el-select :disabled="!is_create" v-model="projectInfo.report_template_id" placeholder="请选择" clearable
                  class="setInput width350">
                  <el-option v-for="(item, index) in reportTemplateList" :key="index" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
                <span v-if="projectInfo.report_template_id" class="width200">
                  <el-button :disabled="!is_create" class="cancelButton margin-left10"
                    @click="goToPreviewModel">预览</el-button>
                  <el-button :disabled="!is_create" class="cancelButton margin-left10"
                    @click="goToEditModel">编辑</el-button>
                </span>
              </div>

              <!-- 测试习题 -->
              <!-- <div>
                                <span>测试习题：</span> 
                                <el-select v-model="projectInfo.exam_paper_id" placeholder="请选择" clearable class="width350">
                                    <el-option
                                    v-for="(item,index) in examPaperList"
                                    :key="index"
                                    :label="item.title"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                                <span v-if="projectInfo.exam_paper_id" class="width300">
                                    <el-button  class="margin-left10" @click="previewExam()" >预览</el-button>
                                    <el-button  class="margin-left10"  @click="goToNewLabel('/teacher/questionBankUpdate',{'bank_id':projectInfo.exam_paper_id})">编辑</el-button>
                                    <el-button  class="margin-left10"  @click="weightDialogOpen('test','测验规则设置','40%')">规则</el-button>
                                </span>
              </div>-->
              <!-- 签到时间 -->
              <div>
                <span class="setSpan">签到模式：</span>
                <el-select :disabled="!is_create" v-model="projectInfo.sign_type" placeholder="请选择" clearable
                  class="setInput width350">
                  <el-option label="默认模式" :value="0"></el-option>
                  <el-option label="快捷模式(无座号，无刷分)" :value="1"></el-option>
                </el-select>
              </div>
              <div>
                <span class="setSpan">耗材设置：</span>
                <el-button :disabled="!is_create" class=" themeButton" @click="consumablesVisible = true">耗材设置</el-button>
              </div>
            </div>
            <div v-else>
              <div class="recover-button">
                <h3>{{ projectName }}</h3>
                <br />
                <el-button :disabled="!is_create" class="themeButton"
                  @click="handleOpen('recover', '恢复项目')">恢复</el-button>
              </div>
            </div>
          </div>
          <div class="noCourse" v-else>
            <div class="text">暂无项目，</div>
            <div class="text clickcreateCourse" @click="handleOpen('create', '添加项目')">点击新建项目</div>
          </div>
          <div class="saveButton">
            <el-button :disabled="!is_create" class="save-set-button themeButton"
              @click="updateProjectSet()">保存</el-button>
          </div>
        </el-tab-pane>
        <!-- 权重 -->
        <el-tab-pane label="权 重" name="second" class="weight">
          <div v-if="rightContentShow" class="setDetail weightBox">
            <div>
              <div class="inline_block weightText">总权重须 100%，</div>
              <!-- <i class="el-icon-success green" v-if="weight_total == 100"></i><i class="el-icon-error red" v-else> </i>&nbsp;&nbsp; -->
              <div class="inline_block weightTextRed">当前总和 {{ weight_total }}%</div>
            </div>
            <div class="weightTable">
              <div class="inline_block weightTableTextq">权重</div>
              <div class="inline_block weightTableText">操作</div>
            </div>
            <!-- 项目名称 -->
            <div class="weightInput">
              <span class="wight-title-span">虚拟测验：</span>
              <el-input :disabled="!is_create" v-model.number="weightInfo.xnsy" placeholder="请输入虚拟权重" class="weight-input"
                type="number" @blur="weightReckon()"></el-input>&nbsp;%
              <!-- <span class="wight-span cursor FontBlue" @click="weightDialogOpen('virtual','虚拟测验设置','40%')">计分方式，关卡配置，语言，是否考核，刷分方式</span> -->
              <span class="wight-span cursor">
                <div v-show="is_create" class="bindText FontBlue" @click="weightDialogOpen('virtual', '虚拟测验设置', '50%')">设置
                </div>
              </span>
            </div>
            <!-- 单元人数 -->
            <div class="weightInput">
              <span class="wight-title-span">考试测验：</span>
              <el-input :disabled="!is_create" v-model.number="weightInfo.yxcy" placeholder="请输入测试权重" class="weight-input"
                type="number" @blur="weightReckon()"></el-input>&nbsp;%
              <span class="wight-span cursor">
                <!-- <span class="width220 FontBlue" @click="weightDialogOpen('test','测验规则设置','40%')">方式，时间，通过，明细</span> &nbsp;| &nbsp; -->
                <div v-show="is_create" class="bindText FontBlue" @click="weightDialogOpen('test', '测验规则设置', '60%')">设置
                </div>
                <!-- <span class="width80 detailcolor" @click="setRecordRation"> 成绩定量</span>&nbsp;| &nbsp;-->
                <!-- <span class="width220 detailcolor FontBlue" @click="goToNewLabel('/manager/teacher/article/articlePreview',{'article_id':1789})" >  测试方式详细说明</span>  -->
              </span>
            </div>
            <!-- 学时 -->
            <div class="weightInput">
              <span class="wight-title-span">课堂操作：</span>
              <el-input :disabled="!is_create" v-model.number="weightInfo.ktcz" placeholder="请输入操作权重" class="weight-input"
                type="number" @blur="weightReckon()"></el-input>&nbsp;分
              <!-- <span class="wight-span cursor FontBlue" @click="weightDialogOpen('handle','课堂操作规则','50%')">标准明细</span> -->
              <span class="wight-span cursor">
                <div v-show="is_create" class="bindText FontBlue" @click="weightDialogOpen('handle', '课堂操作规则', '50%')">设置
                </div>
              </span>
            </div>
            <!-- 学时 -->
            <div class="weightInput">
              <span class="wight-title-span">实验报告：</span>
              <el-input :disabled="!is_create" v-model.number="weightInfo.sybg" placeholder="请输入报告权重" class="weight-input"
                type="number" @blur="weightReckon()"></el-input>&nbsp;%
              <!-- <span class="wight-span cursor FontBlue" @click="weightDialogOpen('template','更改报告模板','30%')">选择编辑</span> -->
              <span class="wight-span cursor">
                <div v-show="is_create" class="bindText FontBlue" @click="weightDialogOpen('template', '更改报告模板', '40%')">设置
                </div>
              </span>
            </div>
            <!-- 学时 -->
            <div class="weightInput">
              <span class="wight-title-span">其他活动：</span>
              <el-input :disabled="!is_create" v-model.number="weightInfo.yxmb" placeholder="请输入其他活动权重"
                class="weight-input" type="number" @blur="weightReckon()"></el-input>&nbsp;%
            </div>
            <!-- 保存按钮 -->
            <div class="weightsaveButton">
              <el-button :disabled="!is_create" class="width100 themeButton" @click="updateWeight()">保存</el-button>
            </div>
          </div>
          <div v-else>
            <div class="recover-button">
              <h3>{{ projectName }}</h3>
              <br />
              <el-button :disabled="!is_create" class="themeButton" @click="handleOpen('recover', '恢复项目')">恢复</el-button>
            </div>
          </div>
        </el-tab-pane>
        <!-- 成绩 -->
        <el-tab-pane label="成 绩" name="third">
          <div v-if="rightContentShow" class="setDetail">
            <!-- 功能div -->
            <div class="title-div">
              <div class="title-div-select-mark">
                <el-select v-model="mark_group_id" placeholder="请选择" @change="markChangeGroup()">
                  <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div class="expSearch">
                <el-input clearable placeholder="请输入姓名或学号" v-model="mark_student_name" @clear="getStudentMarkListSearch">
                  <el-button slot="append" class="el-icon-search" @click="getStudentMarkListSearch"></el-button>
                </el-input>
              </div>
              <div class="title-div-button-mark">

                <el-button class="float_right themeButton" @click="markDialogOpen('totalmark')">计算总分</el-button>
                <!-- <el-button type="success">导入模板下载</el-button> -->
                <!-- 弹窗，有模板下载按钮 -->
                <div class="showCode float_right">
                  <el-dropdown :hide-on-click="false">
                    <span class="el-dropdown-link">
                      提醒
                      <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <div class="codeItem" @click="markDialogOpen('subscribe')">催预约</div>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <div class="codeItem" @click="markDialogOpen('report')">催报告</div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <!-- 成绩分析 -->
                <div class="sendNotice float_right" @click="shouScoreDialog()">成绩分析</div>
                <div class="sendNotice float_right" @click="exportStudentListScore()">导出成绩详情Excel</div>
                <div class="sendNotice float_right" @click="markDialogOpen('import')">导入外部成绩</div>
                <div class="sendNotice float_right" @click="exportExamDetail()">导出测验详情</div>
              </div>
            </div>
            <!-- <table> -->
            <el-table :data="studentList" v-if="studentList.length > 0" stripe style="width: 100%"
              @sort-change="scoresortChange">
              <!-- 序号 -->
              <el-table-column type="index" :index="scoreindexMethod" label="序号" width="60" sortable></el-table-column>
              <!-- 学号 -->
              <el-table-column prop="personal_id" label="学号" width="150" sortable="custom"></el-table-column>
              <!-- 姓名 -->
              <el-table-column prop="name" label="姓名" width="90"></el-table-column>
              <!-- 上课时间 -->
              <el-table-column prop="start" label="上课时间" width="200"></el-table-column>
              <!-- 虚拟 -->
              <el-table-column prop="exp_score" label="虚拟成绩" sortable="custom"></el-table-column>
              <!-- 测试 -->
              <el-table-column prop="exam_score" label="测试成绩" sortable="custom"></el-table-column>
              <!-- 操作 -->
              <el-table-column prop="opt_score" label="操作成绩" sortable="custom"></el-table-column>
              <!-- 报告 -->
              <el-table-column prop="report_score" label="报告成绩" sortable="custom"></el-table-column>
              <!-- 其他 -->
              <!-- <el-table-column
                            prop="date"
                            label="其他成绩"
                            sortable>
              </el-table-column>-->
              <!-- 总分 -->
              <el-table-column label="总分" prop="final_score" sortable="custom"></el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                    <div class="operatorItem floatLeft" @click="calcUserById(scope.row)">计算总分</div>
                </template>
              ></el-table-column>
            </el-table>
            <div v-else>
              <div class="messageCode" v-show="messageCode == -1">
                {{ messageTip }} ，请先在“
                <div @click="gocourseProject" class="messageText">课程—项目</div>”中进行绑定
              </div>
              <div class="messageCode" v-show="messageCode > 0 && studentList.length == 0">
                {{ messageTip }} ，请先在“
                <div @click="gogroupList" class="messageText">班级—列表</div>”中进行添加
              </div>
            </div>
            <!-- 分页 -->
            <div class="page-div padding-top">
              <el-pagination @current-change="handleMarkPageChange" background layout="prev, pager, next"
                :current-page.sync="markPage" :total="markDataTotal" :page-size="markPageSize"
                v-if="markDataTotal != 0"></el-pagination>
            </div>
          </div>
          <div v-else>
            <div class="recover-button">
              <h3>{{ projectName }}</h3>
              <br />
              <el-button class="themeButton" :disabled="!is_create" @click="handleOpen('recover', '恢复项目')">恢复</el-button>
            </div>
          </div>
        </el-tab-pane>
        <!-- 虚拟 -->
        <el-tab-pane label="虚 拟" name="fourth" class="expBox">
          <div v-if="rightContentShow" class="setDetail">
            <!-- 功能div -->
            <div class="vistual-top-div">
              <div class="vistual-group-div">
                <el-select v-model="vistual_group_id" @change="vistualGroupSelectChange()" placeholder="请选择">
                  <el-option v-for="item in vistual_group_list" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
                <el-button class="themeButton alllockButton" @click="vistualDialogOpen('unlock')">全部解锁</el-button>
              </div>
              <div class="vistual-group-div">
                <el-select class="setInput" :disabled="!is_create" v-model="vistual_exp_id" filterable remote
                  reserve-keyword placeholder="请输入虚拟实验名称！" :remote-method="vistualRemoteMethod"
                  @clear="vistualExpSelectClear()" @change="vistualExpSelectChange()" :loading="loading">
                  <el-option v-for="item in vistual_exp_list" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
                <el-button :disabled="!is_create" class="themeButton alllockButton"
                  @click="bindVistualToExp()">保存</el-button>
              </div>

              <!-- <div class="searchBox">
                <div class="float_left searchBoxItem"><el-input  v-model="vistual_student_name" placeholder="请输入姓名或学号"></el-input></div>
                <div class="float_left searchBoxItem"><el-button class="themeButton" @click="getStudentMarkList">搜索</el-button></div>
                
              </div> -->
              <div class="expSearch">
                <el-input clearable placeholder="请输入姓名或学号" v-model="vistual_student_name" @clear="getVistualListSearch">
                  <el-button slot="append" class="el-icon-search" @click="getVistualListSearch"></el-button>
                </el-input>
              </div>
              <div class="vistual-button-div">
                <el-button class="themeButton float_right" @click="vistualDialogOpen('flush')">刷成绩</el-button>
                <el-button class="themeButton float_right" @click="openAnalysisPage">学情分析</el-button>
                <el-button class="themeButton float_right" @click="brushPoinrUpdate('delete')">删除关联</el-button>
                <el-button class="themeButton float_right" @click="brushPoinrUpdate('add')">创建关联</el-button>
                <!-- 功能待开发 -->
                <!-- <div class="sendNotice float_right" >统计</div> -->
                <!-- <div class="sendNotice float_right" >导出</div> -->
              </div>
            </div>
            <!-- <hr> -->
            <div v-if="vistual_data_list.length > 0">
              <el-table @sort-change="sortChange" :data="vistual_data_list" style="width: 100%">
                <!-- <el-table-column sortable type="index" label="序号" width="50"></el-table-column> -->
                <!-- 序号 -->
                <el-table-column type="index" :index="virtualindexMethod" label="序号" width="80"
                  sortable></el-table-column>
                <!-- 学号 -->
                <el-table-column prop="personal_id" label="学号" sortable="custom"></el-table-column>
                <!-- 姓名 -->
                <el-table-column prop="name" label="姓名"></el-table-column>
                <!-- 解锁 -->
                <el-table-column prop="status" label="解锁状态"></el-table-column>
                <!-- 成绩 -->
                <el-table-column prop="exp_score" label="成绩" sortable="custom"></el-table-column>
                <!-- 虚拟实验操作记录 -->
                <el-table-column align="left" label="虚拟实验操作记录" width="150">
                  <template slot-scope="scope">
                    <div class="expoperatorItem" @click="getVirtualRecordList(scope.row)">查看</div>
                  </template>
                </el-table-column>
                <!-- 最后更新时间 -->
                <el-table-column prop="updateTime" label="最后更新时间" width="180"></el-table-column>
                <!-- 实验完成度 -->
                <el-table-column prop="experimentTimes" label="实验完成度"></el-table-column>
                <!-- 自动刷分 -->
                <el-table-column prop="brash_info" label="关联"></el-table-column>
                <!-- 虚拟实验操作记录 -->
                <el-table-column align="left" label="操作">
                  <template slot-scope="scope">
                    <div style="cursor:pointer;color:#0d5570;" @click="lockUserExp(scope.row)" v-if="scope.row.is_lock == 0">冻结</div>
                    <div style="cursor:pointer;color:#0d5570;" @click="lockUserExp(scope.row)" v-else >解冻</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div v-else>
              <div class="messageCode" v-show="messagevirtualCode === -3">
                {{ messagevirtualTip }} ，请先在“
                <div @click="gogroupList" class="messageText">班级—列表</div>”中进行添加
              </div>
            </div>
            <!-- 分页 -->
            <div class="page-div padding-top">
              <el-pagination @current-change="handleVistualPageChange" background layout="prev, pager, next"
                :current-page.sync="vistualPage" :total="vistualDataTotal" :page-size="vistualPageSize"
                v-if="vistual_data_list.length > 0"></el-pagination>
            </div>
          </div>
          <div v-else>
            <div class="recover-button">
              <h3>{{ projectName }}</h3>
              <br />
              <el-button class="themeButton" :disabled="!is_create" @click="handleOpen('recover', '恢复项目')">恢复</el-button>
            </div>
          </div>
        </el-tab-pane>
        <!-- 报告 -->
        <el-tab-pane label="报 告 评 阅" name="fifth">
          <div v-if="rightContentShow" class="setDetail">
            <!-- 功能div -->
            <div class="report_top_div">
              <div class="report_second_div">
                <el-select class="report_status_box" v-model="report_group_name" placeholder="请选择班级"
                  @change="reportSelectChange()" clearable>
                  <el-option v-for="item in report_group_list" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
                <el-select class="report_type_box" v-model="report_status_id" placeholder="请选择类型"
                  @change="reportSelectChange()" clearable>
                  <el-option v-for="item in report_status_id_list" :key="item.id" :label="item.name"
                    :value="item.name"></el-option>
                </el-select>
              </div>
              <div class="expSearch">
                <el-input clearable placeholder="请输入姓名或学号" v-model="report_student_name"
                  @clear="getStudentReportListSearch">
                  <el-button slot="append" class="el-icon-search" @click="getStudentReportListSearch"></el-button>
                </el-input>
              </div>
              <div class="reportText">
                <div class="uncheckNum">{{ notReviewed }}</div>
                <div class="uncheckText">待批</div>
                <div class="uncheckNum">{{ Reviewed }}</div>
                <div class="uncheckText">已批</div>
                <div class="unCommitNum">{{ notReportNum }}</div>
                <div class="uncheckText">未交</div>
                <div class="uncheckText">/ {{ rightShowTotalNum }}人</div>
                <div class="reportdownLoad" >
                  <el-button class="themeButton" @click="copyPdfLink">复制PDF链接</el-button>
                  <el-button class="themeButton" @click="showdownReport">下载</el-button>
                  <el-button class="themeButton" @click="showDuplicateDialog">查重</el-button>
                </div>
              </div>
            </div>
            <el-table :data="report_data_list" stripe style="width: 100%" @sort-change="reportsortChange">
              <el-table-column type="index" :index="reportindexMethod" width="50" label="序号"></el-table-column>
              <!-- 学号 -->
              <el-table-column prop="personal_id" label="学号" width="180"></el-table-column>
              <!-- 姓名 -->
              <el-table-column prop="username" label="姓名"></el-table-column>
              <!-- 上课时间 -->
              <!-- <el-table-column prop="classTime" label="上课时间" sortable width="180"></el-table-column> -->
              <!-- 提交时间 -->
              <el-table-column prop="addtime" label="提交时间" width="200" sortable="custom"></el-table-column>
              <!-- 状态 -->
              <el-table-column prop="report_status_str" label="状态">
                <template slot-scope="scope">
                  <div>{{ scope.row.report_status_str == '未答题' ? '未填写' : scope.row.report_status_str }}</div>
                </template>
              </el-table-column>.
              <!-- 分数 -->
              <el-table-column prop="report_score" label="分数" sortable="custom"></el-table-column>
              <el-table-column prop="khz" label="重复率" ></el-table-column>
              <!-- 相似度 -->
              <!-- <el-table-column
                            prop="date"
                            label="相似度">
              </el-table-column>-->
              <!-- 操作 -->
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <!-- <div  class="reportCheck" v-if="scope.row.report_status != 4 && scope.row.report_status != 5 && scope.row.report_status != 3">评阅</div> -->
                  <div class="reportCheck left" v-if="scope.row.report_status == 4" @click="jumpToReportEdit(scope.row)">评阅报告
                  </div>
                  <div class="reportLook left" v-if="scope.row.report_status == 5" @click="jumpToReportEdit(scope.row)">查看报告
                  </div>
                  <div class="reportLook left" style="margin-left: 10px;" v-if=" scope.row.khz_result !== null && scope.row.khz_result != '' " @click="getSSIM(scope.row)">查看相似度
                  </div>
                  <div class="reportLook left" style="margin-left: 15px;" v-if="scope.row.report_status == 5 && scope.row.pdf_path" @click="lookReportPdf(scope.row.pdf_path)">查看PDF
                  </div>
                  <div class="reportCheck left" style="margin-left: 15px;" v-if="scope.row.report_status == 5 && !scope.row.pdf_path" @click="createReportPDF(scope.row)">生成PDF
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-div padding-top">
              <el-pagination @current-change="handleReportPageChange" background layout="prev, pager, next"
                :current-page.sync="reportPage" :total="reportDataTotal" :page-size="reportPageSize"
                v-if="reportDataTotal != 0"></el-pagination>
            </div>
          </div>
          <div v-else>
            <div class="recover-button">
              <h3>{{ projectName }}</h3>
              <br />
              <el-button class="themeButton" :disabled="!is_create" @click="handleOpen('recover', '恢复项目')">恢复</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
        title="共享项目"
        :visible.sync="dialogShareVisible"
        width="30%"
    >
        <span>
            <span style="font-size: 0.8333vw;">请选择要共享的同事：</span><br><br>
            <el-select v-model="selectTogether" multiple placeholder="请选择" style="width:100%;">
                <el-option
                v-for="item in shareTogetherList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </span>
        <span
            slot="footer"
            class="dialog-footer"
            v-if="handleSign != 'is_qrcode'">
            <el-button class="themecancelButton" @click="dialogShareVisible = false">取 消</el-button>
            <el-button class="themeButton" @click="dialogShareFix()">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog
        title="耗材设置"
        :visible.sync="consumablesVisible"
        width="50%"
    >
      <!-- 测验规则 -->
      <span >
        <!-- 当选择其他权重时的表格 -->
        <div class="consumablesBox">
          <p style="margin-bottom: 15px;"> 
            <el-button type="primary" @click="addConsumablesLine" class="themeButton">新增耗材+</el-button>
          </p>
          <p>
            <!-- 这是一行数据 -->
            <span class="consumables-line" v-for="(val, ind) in consumables" :key="ind"  >
              <!-- 题库下拉列表 -->
              <span class="width350">
                <div class="title" style="display:inline-block">名称:</div>
                <div style="display:inline-block">
                  <el-input v-model="val.name"  placeholder="请输入耗材名称" clearable></el-input>
                </div>
              </span>
              <!-- 单选 -->
              <span class="consumablesBox-new">
                <span class="title">单位:</span>
                <span><el-input v-model="val.unit" placeholder="请输入单位" clearable></el-input></span>
              </span>
              <!-- 多选 -->
              <span class="consumablesBox-new">
                <span class="title">数量:</span>
                <span><el-input v-model="val.num" placeholder="请输入数量" clearable></el-input></span>
              </span>
              <span style="margin-left:10px;" >
                <i class="el-icon-close" style="color: red;cursor: pointer;" @click="deleteConsumablesByIndex(ind)"></i>
              </span>
            </span>
          </p>
        </div>
      </span>
      <span
          slot="footer"
          class="dialog-footer"
          v-if="handleSign != 'is_qrcode'">
          <el-button class="themecancelButton" @click="consumablesVisible = false">取 消</el-button>
          <el-button class="themeButton" @click="dialogConsumablesFix()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 成绩定量弹窗 -->
    <!-- <el-dialog title="成绩定量" :visible.sync="RecordRationVisible"  width="30%">
            <el-form :model="RecordRation" status-icon :rules="RecordRationRules"  label-width="100px" class="demo-ruleForm" ref="RecordRationRef">
                <el-form-item label="考试测验" prop="yxcy">
                  <el-input  v-model.number="RecordRation.yxcy" @input="UpdateRecordRation"></el-input>
                </el-form-item>
                <el-form-item label="预习模板" prop="yxmb">
                  <el-input  v-model.number="RecordRation.yxmb" @input="UpdateRecordRation"></el-input>
                </el-form-item>
                <el-form-item label="虚拟实验" prop="xnsy">
                  <el-input v-model.number="RecordRation.xnsy" @input="UpdateRecordRation"></el-input>
                </el-form-item>
                <el-form-item label="课堂操作" prop="ktcz">
                  <el-input v-model.number="RecordRation.ktcz" @input="UpdateRecordRation"></el-input>
                </el-form-item>
                <el-form-item label="实验报告" prop="sybg">
                  <el-input v-model.number="RecordRation.sybg" @input="UpdateRecordRation"></el-input>
                </el-form-item>
                <el-form-item label="总计">
                  <el-input disabled v-model.number="total"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="upDateGradeResult" >保存</el-button>
                </el-form-item>
            </el-form>
    </el-dialog>-->

    <!-- 权重关卡配置弹窗 -->
    <div class="showweightLevel" v-show="weightLevelDialogVisible">
      <el-dialog :title="weightLevelTitle" :visible.sync="weightLevelDialogVisible" width="30%">
        <span>
          <!-- 操作表格 -->
          <el-table :data="weightVirtualList" stripe style="width: 100%">
            <el-table-column prop="a" label="关卡"></el-table-column>
            <el-table-column label="比例 %" width="180">
              <template slot-scope="scope">
                <span>
                  <el-input v-model="scope.row.b" placeholder="请输入比例数！" type="number"></el-input>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="cancelButton" @click="weightLevelDialogVisible = false">取消</el-button>
          <el-button class="themeButton" type="primary" @click="weightVirtualLevelOK()">确定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 虚拟实验操作记录弹窗 -->
    <div class="expDialogVisible">
      <el-dialog :title="expDialogTitle" :visible.sync="expDialogVisible" width="50%">
        <el-table :data="expoptList" stripe style="width: 100%">
          <el-table-column prop="CID" label="关卡" width="50"></el-table-column>
          <!-- 学号 -->
          <el-table-column prop="mode" label="模式"></el-table-column>
          <!-- 姓名 -->
          <el-table-column prop="subtime" width="150" label="时间"></el-table-column>
          <!-- 解锁 -->
          <el-table-column prop="score" label="分数"></el-table-column>
          <!-- 成绩 -->
          <el-table-column label="操作记录">
            <template slot-scope="scope">
              <!-- <div class="expoperatorItem">点击查看详情</div> -->
              <el-popover placement="right" trigger="click">
                <el-table class="deductList" :data="deductList">
                  <el-table-column property="reason" label="原因"></el-table-column>
                  <el-table-column property="scoreChange" label="扣分"></el-table-column>
                </el-table>
                <div class="expoperatorItem" slot="reference" @click="getExpDataDeductById(scope.row)">点击查看详情</div>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 虚拟实验操作记录 -->
          <el-table-column prop="duration" label="耗时"></el-table-column>
          <!-- 最后更新时间 -->
          <el-table-column label="数据">
            <template slot-scope="scope">
              <!-- <div class="expoperatorItem">点击查看详情</div> -->
              <el-popover @hide="clearexpData" placement="right" trigger="click">
                <div v-if="expdataList.length > 0" class="expDataBox clearFix">
                  <div v-for="item in expdataList" :key="item">
                    <div class="expdatalistItem clearFix">
                      <div class="expdatalistItemdiv">{{ item[0] }}</div>
                      <div class="expdatalistItemdiv">{{ item[1] }}</div>
                    </div>
                    <!-- <div class="expdatalistItem"></div> -->
                  </div>
                </div>
                <div v-else>
                  <div class="expdataNo">暂无数据</div>
                </div>
                <div class="expoperatorItem" slot="reference" @click="getExpDataRecordDataById(scope.row)">点击查看详情</div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>

    <!-- 权重操作弹窗 -->
    <el-dialog :title="weightHandleDialogTitle" :visible.sync="weightHandleDialogVisible" width="30%">
      <!-- <span v-if="weightHandleDialogSign == 'edit'">
                <el-input v-model="edithandleContent" placeholder="请输入操作内容" ></el-input>
                <br><br>
                <el-input v-model="edithandleScore" placeholder="请输入操作分数" type="number"></el-input>
      </span>-->
      <!-- <span v-if="weightHandleDialogSign == 'delete'"> <h3>您确定要删除这条数据么？</h3> </span> -->
      <span>
        <h3>您确定要删除这条数据么？</h3>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="weightHandleDialogVisible = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="weightHandleDialogOK()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 权重弹窗 -->
    <el-dialog :title="weightDialogTitle" :visible.sync="weightDialogVisible" :width="weightDialogWidth" class="weight">
      <!-- 绑定报告模板 -->
      <el-form v-if="weightDialogSign == 'template'" class="reportFontsize">
        <el-form-item label="报告模板">
          <el-select v-model="update_report_template_id" placeholder="请选择" clearable class="width350">
            <el-option v-for="(item, index) in reportTemplateList" :key="index" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交时限">
          <div class="submitTime">
            <div>
              <el-radio v-model="radio1" label="1" @click.native="noLimit">无限制</el-radio>
            </div>
            <div class="reportsubmitallTime">
              <el-radio v-model="radio1" label="2" @click.native="timeALL">统一时间截点</el-radio>
              <el-date-picker v-show="radio1 == '2'" class="reportsubmitallTimeBox" v-model="reportsubmitallTime"
                type="datetime" placeholder="请选择"></el-date-picker>
            </div>
            <div class="reportsubmitallTime">
              <el-radio v-model="radio1" label="3" @click.native="timeLimit">实验结束后限时</el-radio>
              <el-input v-show="radio1 == '3'" class="reportsubmitallTimeBox" v-model="reportsubmitlimitTime"
                placeholder="请输入天数" type="number"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="超期补交" v-show="radio1 == 2 || radio1 == 3">
          <div>
            <div class="submitAgain">
              <el-radio v-model="radio2" label="0">允许</el-radio>
            </div>
            <div class="submitAgain">
              <el-radio v-model="radio2" label="1">不允许</el-radio>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="显示成绩" >
          <div>
            <div class="submitAgain">
              <el-radio v-model="is_show_report_score" label="0">显示</el-radio>
            </div>
            <div class="submitAgain">
              <el-radio v-model="is_show_report_score" label="1">不显示</el-radio>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <!-- 操作明细 -->
      <span v-if="weightDialogSign == 'handle'">
        <span class="handle-span">
          <el-input v-model="handleContent" placeholder="请输入操作内容" class="handle-input-one"></el-input>
          <el-input v-model="handleScore" placeholder="请输入操作分数" class="handle-input-two"></el-input>
          <el-select v-model="optSign" placeholder="请选择操作类型" class="handle-input-three">
            <el-option v-for="item in optTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </span>
        <el-button class="optButton" @click="updateHandle()">添加</el-button>
        <br />
        <br />
        <div class="optTable">
          <!-- 操作表格 -->
          <el-table :data="handleList" stripe style="width: 100%">
            <el-table-column prop="des" label="操作内容">
              <template slot-scope="scope">
                <div class="editInput" v-if="scope.row.id == handle_id"
                  @keyup.enter="weightHandleDialogOK(scope.row.sign)">
                  <el-input v-model="edithandleContent" placeholder="请输入操作内容"></el-input>
                </div>
                <div class="editdisInput" v-else>
                  <el-input readonly v-model="scope.row.des" placeholder="请输入操作内容"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="操作分数">
              <template slot-scope="scope">
                <div class="editInput" v-if="scope.row.id == handle_id"
                  @keyup.enter="weightHandleDialogOK(scope.row.sign)">
                  <el-input v-model="edithandleScore" placeholder="请输入操作分数"></el-input>
                </div>
                <div class="editdisInput" v-else>
                  <el-input readonly v-model="scope.row.score" placeholder="请输入操作分数"></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="des" label="操作类型">
              <template slot-scope="scope">
                <el-select :disabled="scope.row.id != handle_id" v-model="scope.row.sign" placeholder="请选择操作类型"
                  class="handle-input-fourth" @change="updateoptcoreType(scope.row.sign)">
                  <el-option v-for="item in optTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <span class="optList">
                  <div class="editOpt" @click="openHandleDialog('edit', '修改操作详情', scope.row)">编辑</div>
                  <div class="delOpt" @click="openHandleDialog('delete', '删除操作', scope.row)">删除</div>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </span>

      <!-- 测验规则 -->
      <span v-if="weightDialogSign == 'test'">
        <p>
          <span class="span-title">签到:</span>
          <span>
            <el-radio v-model="isSgin" label="1" class='radio-width'>是</el-radio>
            <el-radio v-model="isSgin" label="0" class='radio-margin'>否</el-radio>
          </span>
        </p>
        <br />
        <p>
          <span class="span-title">防作弊:</span>
          <span>
            <el-radio v-model="isCheat" label="1" class='radio-width'>是</el-radio>
            <el-radio v-model="isCheat" label="0" class='radio-margin'>否</el-radio>
          </span>
        </p>
        <br />
        <p>
          <span class="span-title">日程:</span>
          <span>
            <el-radio v-model="isCurrentDay" label="1" class='radio-width'>当日</el-radio>
            <el-radio v-model="isCurrentDay" label="2" class='radio-margin'>课上时间(课后15分钟)</el-radio>
            <el-radio v-model="isCurrentDay" label="0" class='radio-margin'>无限制</el-radio>
          </span>
        </p>
        <br />
        <p>
          <span class="span-title">答案查看:</span>
          <span>
            <el-radio v-model="isLook" label="1" class='radio-width'>是</el-radio>
            <el-radio v-model="isLook" label="0" class='radio-margin'>否</el-radio>
          </span>
        </p>
        <br />
        <hr>
        <br>
        <p>
          <span class="span-title">反复测试:</span>
          <span>
            <el-radio v-model="isRepeat" label="1" class='radio-width'>是</el-radio>
            <el-radio v-model="isRepeat" label="0" class='radio-margin'>否</el-radio>
          </span>
        </p>
        <br />
        <p>
          <span class="span-title">成绩计算:</span>
          <span>
            <el-radio v-model="isCalc" label="1" class='radio-width'>最近一次</el-radio>
            <el-radio v-model="isCalc" label="0" class='radio-margin'>最高分</el-radio>
          </span>
        </p>
        <br />
        <hr>
        <br />
        <p>
          <span class="span-title">选课校验:</span>
            <span class="span-content">
              <el-input v-model="examScoreToOpen" placeholder="请输入通过分数！" type="text"></el-input>
          </span>
        </p>
        <br>
        <!-- 当选择其他权重时的表格 -->
        <!-- <div v-else class="examBox"> -->
        <div class="examBox">
          <p style="margin-bottom: 15px;"> 
            <el-button type="primary" @click="addExamLine" class="themeButton">新增测试习题+</el-button>
          </p>
          <p>
            <!-- <span class="span-title">测试习题:</span> -->
            <!-- 这是一行数据 -->
            <span class="exam-line" v-for="(val, ind) in examList" :key="ind"  >
              <!-- 题库下拉列表 -->
              <span class="width350">
                <el-select v-model="val.exam_paper_id" placeholder="请选择测试题库,不选择试题库则不测验" @clear="clearEaxm" clearable @change="getExamPaperNumber(val.exam_paper_id)">
                  <el-option v-for="(item, index) in examPaperList" :key="index" :label="item.title"
                    :value="item.id"></el-option>
                </el-select>
              </span>
              <!-- 单选 -->
              <span class="examBox-new">
                <span class="title">单选题(<span class="question-num">{{ val.radio }}</span>):</span>
                <span><el-input v-model="val.weightRadioNum" placeholder="单选数量" type="number"></el-input></span>
              </span>
              <!-- 多选 -->
              <span class="examBox-new">
                <span class="title">多选题(<span class="question-num">{{ val.checkbox }}</span>):</span>
                <span><el-input v-model="val.weightCheckboxNum" placeholder="单选数量" type="number"></el-input></span>
              </span>
              <!-- 判断 -->
              <span class="examBox-new">
                <span class="title">判断题(<span class="question-num">{{ val.judge }}</span>):</span>
                <span><el-input v-model="val.weightJudgeNum" placeholder="单选数量" type="number"></el-input></span>
              </span>
              <span style="margin-left:10px;" >
                <i class="el-icon-close" style="color: red;cursor: pointer;" @click="deleteExamByIndex(ind)"></i>
              </span>
            </span>
          </p>
          <br />
          <p>
            <span class="span-title">通过分数(折合):</span>
            <span class="span-content">
              <el-input v-model="weightPassScore" placeholder="请输入题目数量" type="number"></el-input>
            </span>
          </p>
          <br />
          <p>
            <span class="span-title">考试时间(分钟):</span>
            <span class="span-content">
              <el-input v-model="weightTestTime" placeholder="请输入题目数量" type="number"></el-input>
            </span>
          </p>
        </div>
      </span>

      <!-- 虚拟测验 -->
      <span v-if="weightDialogSign == 'virtual'" class="virtualDialog">
        <el-form label-position="left" label-width="7vw">
          <!-- <el-form-item label="群组">
                <el-select v-model="weightVirtualTest" placeholder="请选择" clearable class="width400">
                            <el-option label="默认计分法" :value="0"></el-option>
                            <el-option label="高分均值法(课上时间)" :value="1"></el-option>
                            <el-option label="各关都做法" :value="2"></el-option>
                            <el-option label="指定关卡" :value="3"></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="虚拟实验">
                    <el-select v-model="weightVirtualTest" placeholder="请选择" clearable class="width400">
                                <el-option label="默认计分法" :value="0"></el-option>
                                <el-option label="高分均值法(课上时间)" :value="1"></el-option>
                                <el-option label="各关都做法" :value="2"></el-option>
                                <el-option label="指定关卡" :value="3"></el-option>
                    </el-select>
          </el-form-item>-->
          <el-form-item label="数据采用：">
            <div>
              <div class="submitAgain">
                <el-radio v-model="datatype" label="0">最高成绩</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="datatype" label="1">第一次</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="datatype" label="2">最后一次</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="时间：">
            <div>
              <div class="submitAgain" @click="allTime">
                <el-radio v-model="classTime" label="1">不限</el-radio>
              </div>
              <!-- <br> -->
              <div class="submitAgain" @click="limitTime">
                <el-radio v-model="classTime" label="2">限制时间</el-radio>
              </div>
              <div class="submitpicker" v-show="classTime == 2">
                <el-date-picker v-model="classtimeRander" format="yyyy-MM-dd HH:mm" type="datetimerange"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="语言：">
            <div>
              <div class="submitAgain">
                <el-radio v-model="yuyan" label="0">不限</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="yuyan" label="1">英文</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="模式：">
            <div>
              <div class="submitAgain">
                <el-radio v-model="moshi" label="1">不限</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="moshi" label="2">考核</el-radio>
              </div>
              <!-- <div class="submitAgain">
                            <el-radio v-model="moshi" label="1">练习</el-radio>
              </div>-->
            </div>
          </el-form-item>
          <el-form-item label="刷分方式：">
            <div>
              <div class="submitAgain">
                <el-radio v-model="flushType" label="2">手动(教师认定)</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="flushType" label="1">自动</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="选课校验">
            <div>
              <div class="submitAgain">
                <el-radio v-model="is_open_exp" label="0">不校验</el-radio>
              </div>
              <div class="submitAgain">
                <el-radio v-model="is_open_exp" label="1">校验</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关卡">
            <div class="cidIcon" @click="handleDetailExplain">
              <i class="el-icon-warning-outline"></i>：
            </div>
            <!-- <div class="submitAgain">
              <el-radio v-model="cidType" label="1" @click.native="handleCid(true)">权重均分</el-radio>
            </div>
            <div class="submitAgain">
              <el-radio v-model="cidType" label="2">自定义</el-radio>
            </div> -->
            <div class="clearallCid" v-show="cidType == 2" @click="handleCid(false)">全部清零</div>
            <div class="clearallCid" v-show="cidType == 2" @click="handleCid(true)">权重均分</div>
            <div class="clearallCid " style='float:left;color: #606266;'>权重总和:{{ cidWeightTotal }}</div>
            <!-- <div class="floatLeft">
                        <div class="levelIndex">序号</div>
                        <div class="levelWeight">权重</div>
            </div>-->
            <div class="floatLeft levelVirtualBox" v-show="cidType == 2">
              <div class="inline_block levelVirtual" v-for="(item) in weightVirtualList" :key="item.a">
                <div class="floatLeft cidIndex">{{ item.a }}</div>
                <div class="floatLeft">
                  <el-input v-model="item.b" placeholder @change="CidChange"></el-input>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
                    <div>
                        <el-button class="clearlButton" @click="handleCid(false)">清空</el-button>
                        <el-button class="availButton" @click="handleCid(true)">均分</el-button>
                    </div>
          </el-form-item>-->
        </el-form>
        <!-- <p>
                    <span class="span-title">
                        虚拟考核:
                    </span>
                    <span class="span-content">
                        <el-select v-model="weightVirtualTest" placeholder="请选择" clearable class="width400">
                            <el-option label="默认计分法" :value="0"></el-option>
                            <el-option label="高分均值法(课上时间)" :value="1"></el-option>
                            <el-option label="各关都做法" :value="2"></el-option>
                            <el-option label="指定关卡" :value="3"></el-option>
                        </el-select>
                    </span>
                </p>
                <br>
                <p>
                    <span class="span-title">
                        语言选择:
                    </span>
                    <span class="span-content">
                        <el-select v-model="weightVirtualLanguage" placeholder="请选择" clearable class="width400">
                            <el-option label="默认" :value="0"></el-option>
                            <el-option label="英文" :value="1"></el-option>
                        </el-select>
                    </span>
                </p>
                <br>
                <p>
                    <span class="span-title">
                        模式选择:
                    </span>
                    <span class="span-content">
                        <el-select v-model="weightVirtualMode" placeholder="请选择" clearable class="width400">
                            <el-option label="默认(无要求)" :value="1"></el-option>
                            <el-option label="考核" :value="2"></el-option>
                        </el-select>
                    </span>
                </p>
                <br>
                <p>
                    <span class="span-title">
                        关卡配置:
                    </span>
                    <span class="span-content cursor" @click="weightLevelDialogVisible = true">
                        点击配置
                    </span>
                </p>
        -->
      </span>

      <span slot="footer" class="dialog-footer" v-if="weightDialogSign == 'virtual'">
        <el-button class="themecancelButton" @click="recoverRules">恢复默认规则</el-button>
        <el-button class="themeButton" type="primary" @click="weightDialogOK">保存</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else-if="weightDialogSign == 'template'">
        <el-button class="themecancelButton" @click="recoverTemplate">恢复默认规则</el-button>
        <el-button class="themeButton" type="primary" @click="weightDialogOK">保存</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else-if="weightDialogSign !== 'handle'">
        <el-button class="themecancelButton" @click="weightDialogVisible = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="weightDialogOK">保存</el-button>
      </span>
    </el-dialog>

    <!-- 虚拟tab页弹窗 -->
    <el-dialog title="提示" :visible.sync="vistual_dialog" width="25%" class="el-dialog_body">
      <span>
        <!-- 刷分 -->
        <span v-if="vistual_sign == 'flush'">
          <div>
            <h3>提交将覆盖日程中虚拟实验成绩，请谨慎操作！</h3>
          </div>
          <div class="useAlling">
            <el-checkbox v-model="useScoreAlling">应用于该项目所有在修班级</el-checkbox>
          </div>
        </span>
        <!-- 解锁 -->
        <span v-if="vistual_sign == 'unlock'" class="expScore">
          <div>
            <h3>
              账户余额：
              <span>{{ user_balance }}</span> 积分
            </h3>
          </div>
          <br />
          <div>
            <h3>
              实验{{ vistual_nopaycount }}人购买需：
              <span>{{ unLockCost }}</span> 积分
            </h3>
          </div>
          <br />
          <div>
            <h3>是否购买？</h3>
          </div>
        </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="vistual_dialog = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="vistualDialogExec()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 成绩tab页弹窗 -->
    <el-dialog title="提示" :visible.sync="markDialogVisible" width="25%" class="el-dialog_body">
      <span>
        <!-- 导入成绩 -->
        <span v-if="markDialogSign == 'import'">
          <span class="uploadTip">导入成绩后会覆盖之前的成绩,请谨慎操作。</span>
          <span class="uploadtipButton" @click="downloadImportTemplate()">点击下载模板</span>
          <div>
            <el-upload class="upload-demo" :action="scoreUploadUrl" :data="{ id: project_id }"
              :on-success="markHandleAvatarSuccess" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1"
              :on-exceed="handleExceed" :before-upload="markHandleBeforeUpload" :file-list="fileList">
              <el-button class="uploadButton">点击上传文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
            </el-upload>
          </div>
        </span>
        <!-- 导出 -->
        <span v-if="markDialogSign == 'export'" class="center"></span>
        <!-- 催预约 -->
        <span v-if="markDialogSign == 'subscribe'" class="center">
          <div>
            <h2>是否催预约？</h2>
          </div>
          <br />
          <div>
            <h3>有 {{ noSubscribeNum }} 个同学还没有预约</h3>
          </div>
        </span>
        <!-- 催报告 -->
        <span v-if="markDialogSign == 'report'" class="center">
          <div>
            <h2>是否催报告？</h2>
          </div>
          <br />
          <div>
            <h3>有 {{ noReportSubmitNum }} 个同学还没有提交报告</h3>
          </div>
        </span>
        <!-- 计算总分 -->
        <span v-if="markDialogSign == 'totalmark'">
          <div>
            <!-- <h3>当前采用{{ expscoreString }}，是否继续？</h3> -->
            <h3>重新计算总分将覆盖之前成绩，是否继续！</h3>
          </div>
          <!-- <div>
            <h3>刷新将覆盖日程中虚拟实验成绩，请谨慎操作。</h3>
          </div> -->
          <!-- <div class="useAlling">
            <el-checkbox v-model="useAlling">应用于该项目所有在修班级</el-checkbox>
          </div> -->
        </span>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="markDialogVisible = false">取消</el-button>
        <el-button v-if="markDialogSign == 'import'" class="importFileButton" @click="markDialogExec()">导入</el-button>
        <el-button class="confirmButton themeButton" v-else type="primary" @click="markDialogExec()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 添加/修改/删除/恢复项目弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <span class="export-span">
        <!-- 添加 -->
        <span v-if="handleSign == 'create' || handleSign == 'import'">
          <el-tabs v-model="addProjectActive" @tab-click="projectTabHandleClick">
            <el-tab-pane label="添加项目" name="one">
              <el-input v-model="projectName" placeholder="请输入项目名称"></el-input>
              <el-select v-model="createexpID" clearable filterable placeholder="请选择虚拟实验(选填)" class="createexpSelect">
                <el-option v-for="item in createexpList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-tab-pane>
            <el-tab-pane label="导入项目" name="two">
              <div>
                <div class="uploadProjectTip" @click="downloadProjectTemplate()">点击下载模板</div>
                <div>
                  <el-upload class="upload-demo" :action="projectUploadUrl" :data="{ id: project_id }"
                    :on-success="projectUploadSuccess" :on-remove="projectUploadRemove"
                    :before-remove="projectBeforeRemove" :limit="1" :on-exceed="projectUploadExceed"
                    :before-upload="projectBeforeUpload" :file-list="projectFileList">
                    <el-button class="uploadButton">点击上传文件</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过5MB</div>
                  </el-upload>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </span>
        <!-- 重命名 -->
        <span v-if="handleSign == 'rename'">
          <el-input v-model="projectName" placeholder="请输入模板名称"></el-input>
        </span>
        <!-- 删除 -->
        <span v-else-if="handleSign == 'delete'" class="dialog-span">
          <h3>您确定要删除 "{{ projectName }}" 么？</h3>
        </span>
        <!-- 恢复 -->
        <span v-else-if="handleSign == 'recover'" class="dialog-span">
          <h3>您确定要恢复 "{{ projectName }}" 么？</h3>
        </span>
        <!-- 彻底删除 -->
        <span v-else-if="handleSign == 'delete_completely'" class="dialog-span">
          <h3>您确定要彻底删除 "{{ projectName_complete }}" 么？</h3>
        </span>
        <!-- 添加同事 -->
        <span v-else-if="handleSign == 'add_together'" class="dialog-span">
          <el-select class="width100" v-model="together_id" filterable remote reserve-keyword placeholder="请输入同事姓名或工号"
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </span>
      </span>
      <span slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
        <el-button class="cancelButton" @click="dialogVisible = false">取消</el-button>
        <el-button v-if="handleSign == 'import'" class="importFileButton" @click="dialogFix()">导入</el-button>
        <el-button v-if="handleSign == 'create'" class="importFileButton" @click="dialogFix()">新建</el-button>
        <el-button class="themeButton"
          v-if="handleSign == 'delete' || handleSign == 'recover' || handleSign == 'delete_completely' || handleSign == 'add_together' || handleSign == 'rename'"
          type="primary" @click="dialogFix()">确定</el-button>
      </span>
    </el-dialog>

    <div class="dialog-report-duibi">
      <el-dialog
        title="报告详情对比"
        :visible.sync="reportDetailVisiable"
        width="90%"
        :before-close="handleClose">
        <div style="width: 100%;height: 780px;">
          <span style="float:left;width:49%;border-right:1px #CCC solid;padding-right:2%;" >
            <div v-html="currentReportRecordArray.username" style="width: 100%;height: 30px;line-height: 30px;font-size: 18px;font-weight: 600;"></div>
            <div v-html="currentReportRecordArray.title" style="width: 100%;height: 30px;line-height: 30px;font-size: 16px;"></div>
            <div  style="width: 100%;height: 30px;line-height: 30px;font-size: 16px;">提交时间：{{currentReportRecordArray.addtime}}</div>
            <div v-html="currentReportRecordArray.value" style="height: 640px;overflow-y: scroll;" v-if="currentReportRecordArray.type == 'text' || currentReportRecordArray.type == 'image' || currentReportRecordArray.type == 'xuni'"></div>
            <div style="height: 640px;overflow-y: scroll;" v-else class="table-div">
              <el-row v-for="(trItem, trIndex) in currentReportRecordArray.value" :key="trIndex">
                    <!-- @ 背景 #FFFFFF input -->
                    <!-- # 背景 #FAF8CF input -->
                    <!-- '' 背景 #D3D3D3 不可编辑 -->
                    <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                    
                        <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                            <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                        </div>
                          <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                            <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                        </div>
                        <div v-else>
                            <div v-if="tdItem">
                                <el-input  v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                            </div>
                            <div v-else>
                                <el-input v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
          </span>
          <span style="float:left;width:50%;padding-left:2%;" >
            <div v-html="contrastReportRecordArray.username" style="width: 100%;height: 30px;line-height: 30px;font-size: 18px;font-weight: 600;"></div>
            <div v-html="contrastReportRecordArray.title" style="width: 100%;height: 30px;line-height: 30px;font-size: 16px;"></div>
            <div  style="width: 100%;height: 30px;line-height: 30px;font-size: 16px;">提交时间：{{contrastReportRecordArray.addtime}}</div>
            <!-- <div v-html="contrastReportRecordArray.value" style="height: 640px;overflow-y: scroll;"></div> -->
            <div v-html="contrastReportRecordArray.value" style="height: 640px;overflow-y: scroll;" v-if="contrastReportRecordArray.type == 'text' || contrastReportRecordArray.type == 'image' || contrastReportRecordArray.type == 'xuni'"></div>
            <div style="height: 640px;overflow-y: scroll;" v-else class="table-div">
              <el-row v-for="(trItem, trIndex) in contrastReportRecordArray.value" :key="trIndex">
                    <!-- @ 背景 #FFFFFF input -->
                    <!-- # 背景 #FAF8CF input -->
                    <!-- '' 背景 #D3D3D3 不可编辑 -->
                    <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                    
                        <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                            <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                        </div>
                          <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                            <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                        </div>
                        <div v-else>
                            <div v-if="tdItem">
                                <el-input  v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                            </div>
                            <div v-else>
                                <el-input v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
          </span>
        </div>
      </el-dialog>
    </div>

    <el-dialog title="报告查重" :visible.sync="showDuplicateDialogVisable" width="50%" class="el-dialog_body">
      <div style="position: absolute;left: 30px;top: 70px;">
        <el-checkbox v-model="checkedAll" @change="toggleAll"></el-checkbox> 
        全选
      </div>
      <el-table style="width: 100%" :data="reportContentList" >
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.check" ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" >
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>            
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: center;margin-bottom: 10px;font-size: 16px;">内测阶段最大支持查询8人，超过8人请点击“多人查重申请”按钮</div>
        <el-button class="cancelButton" @click="applyCHeckReportRepeat" type="success">多人查重申请</el-button>
        <el-button class="cancelButton" @click="showDuplicateDialogVisable = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="checkReportRepeat">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="报告相似度查看" :visible.sync="showReportKhzDialogVisable" width="50%" class="el-dialog_body report" >
      <el-table style="width: 100%" :data="khzList" >
        <el-table-column width="70" label="题目">
          <template slot-scope="scope">
            <div v-html="scope.row.question_id"></div>       
          </template>
        </el-table-column>
        <el-table-column prop="repeat" label="相似度" >
          <template slot-scope="scope">
            <div style="cursor:pointer;" v-html="scope.row.repeat" @click="showReportDoubleDetail(scope.row)" ></div>            
          </template>
        </el-table-column>
        <el-table-column prop="username" label="学生姓名" >
          <template slot-scope="scope">
            <div v-html="scope.row.username"></div>            
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div style="cursor:pointer;display:inline-block;" @click="jumpToReportEditReport(scope.row)">查看报告</div> |       
            <div style="cursor:pointer;display:inline-block;" @click="showReportDoubleDetail(scope.row)">查看对比</div>       
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="showReportKhzDialogVisable = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 下载报告弹窗 -->
    <el-dialog title="下载" :visible.sync="showdownreportDialog" width="25%" class="el-dialog_body">
      <span>
        <div class="downreportText">选择群组后下载</div>
        <el-select v-model="downreportId" placeholder="请选择群组">
          <el-option v-for="item in report_group_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </span>

      <div class="TIPS">
        提示：选择班级生成报告批量下载链接，复制链接至第三方下载工具即可完成下载 如:
        <a href="https://www.xunlei.com/" class="xunlei" target="_blank" rel="noopener noreferrer">迅雷</a>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="showdownreportDialog = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="pdfDownload">确定</el-button>
      </span>
    </el-dialog>

    <!-- 百度地图弹窗 -->
    <el-dialog title="地图选点" :visible.sync="baiduDialogVisible" width="70%" class="el-dialog_body">
      <span>
        <baidu-map class="bm-view" ak="Lei5PfqXr4aCFVSbXgkgbP4VAiCBOmF3" :center="baiduCenter" :zoom="17"
          :scroll-wheel-zoom="true" @click="baiduClick"></baidu-map>
      </span>
      <br />
      <span>
        <table class="width100">
          <tr>
            <td>经度</td>
            <td>纬度</td>
          </tr>
          <tr>
            <td>{{ projectInfo.lon }}</td>
            <td>{{ projectInfo.lat }}</td>
          </tr>
        </table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="baiduDialogVisible = false">取消</el-button>
        <el-button class="themeButton" type="primary" @click="baiduDialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
    
    <!-- add buy wyf 2023-07-28 -->
    <!-- 成绩分析弹窗 -->
    <el-dialog
      class="projectDialog"
      :title=" groupName + ' ' + projectNameTitle "
      :visible.sync="projectCourseDialogVisible"
      width="50%"
      @close="onProjectClose"
      >
      <div class="tabNavBar" style="border-top: 1px solid #eee;">
        <span class="projectTabBar" :class="projectShow === '1' ? 'active' : ''" @click="projectTotalShow">总分
          <div :class="projectShow === '1' ? 'tabBarActive' : ''"></div>
        </span>
        <span class="projectTabBar" :class="projectShow === '2' ? 'active' : ''" @click="projectExpShow">虚拟
          <div :class="projectShow === '2' ? 'tabBarActive' : ''"></div>
        </span>
        <span class="projectTabBar" :class="projectShow === '3' ? 'active' : ''" @click="projectTestShow">测试
          <div :class="projectShow === '3' ? 'tabBarActive' : ''"></div>
        </span>
        <span class="projectTabBar" :class="projectShow === '4' ? 'active' : ''" @click="projectOptShow">操作
          <div :class="projectShow === '4' ? 'tabBarActive' : ''"></div>
        </span>
        <span class="projectTabBar" :class="projectShow === '5' ? 'active' : ''" @click="projectReportShow">报告
          <div :class="projectShow === '5' ? 'tabBarActive' : ''"></div>
        </span>
      </div>
      <div v-if="projectShow === '1'">
        <!-- 顶部表格 -->
        <div class="TopTable">
            <table>
              <caption style="margin-bottom: 0.521vw;" align="top">总分分布</caption> 
              <tr>
                <td>分数段</td>
                <td>[90,100]</td>
                <td>[80,90)</td>
                <td>[70,80)</td>
                <td>[60,70)</td>
                <td>[0,60)</td>
                <td>最高分</td>
                <td>最低分</td>
                <td>平均分</td>
                <td>及格率</td>
                <td>相对偏差</td>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ projectTotal.ten_nine_number ? projectTotal.ten_nine_number : 0 }}</td>
                <td>{{ projectTotal.nine_eight_number ? projectTotal.nine_eight_number : 0 }}</td>
                <td>{{ projectTotal.eight_seven_number ? projectTotal.eight_seven_number : 0 }}</td>
                <td>{{ projectTotal.seven_six_number ? projectTotal.seven_six_number : 0 }}</td>
                <td>{{ projectTotal.zero_six_number ? projectTotal.zero_six_number : 0 }}</td>
                <td>{{ projectTotal.maxScore ? projectTotal.maxScore : 0 }}</td>
                <td>{{ projectTotal.minScore ? projectTotal.minScore : 0 }}</td>
                <td>{{ projectTotal.avgScore ? projectTotal.avgScore : 0 }}</td>
                <td>{{ projectTotal.six_percent ? projectTotal.six_percent : 0 }}% </td>
                <td>{{ projectTotal.relativeDev ? projectTotal.relativeDev : 0 }}%</td>
              </tr>
              <tr>
                <td>百分比</td>
                <td>{{ projectTotal.ten_nine_percent ? projectTotal.ten_nine_percent : 0  }}%</td>
                <td>{{ projectTotal.nine_eight_percent ? projectTotal.nine_eight_percent : 0  }}%</td>
                <td>{{ projectTotal.eight_seven_percent ? projectTotal.eight_seven_percent : 0  }}%</td>
                <td>{{ projectTotal.seven_six_percent ? projectTotal.seven_six_percent : 0  }}%</td>
                <td>{{ projectTotal.zero_six_percent ? projectTotal.zero_six_percent : 0  }}%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
        </div>
        <!-- 左侧柱状图 -->
        <div class="left" style="width: 60%;">
          <div id="projectHistogram"></div>
        </div>
        <!-- 右侧雷达图 -->
        <div class="right" style="width: 40%;">
          <div id="projectRadar"></div>
        </div>
      </div>
      <div v-show="projectShow === '2'" style="width: 100%;">
          <!-- 顶部表格 -->
          <div class="TopTable">
            <table>
              <caption style="margin-bottom: 0.521vw;" align="top">虚拟成绩分布</caption> 
              <tr>
                <td>分数段</td>
                <td>[90,100]</td>
                <td>[80,90)</td>
                <td>[70,80)</td>
                <td>[60,70)</td>
                <td>[0,60)</td>
                <td>最高分</td>
                <td>最低分</td>
                <td>平均分</td>
                <td>及格率</td>
                <td>相对偏差</td>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ projectExp.ten_nine_number ? projectExp.ten_nine_number : 0 }}</td>
                <td>{{ projectExp.nine_eight_number ? projectExp.nine_eight_number : 0 }}</td>
                <td>{{ projectExp.eight_seven_number ? projectExp.eight_seven_number : 0 }}</td>
                <td>{{ projectExp.seven_six_number ? projectExp.seven_six_number : 0 }}</td>
                <td>{{ projectExp.zero_six_number ? projectExp.zero_six_number : 0 }}</td>
                <td>{{ projectExp.maxScore ? projectExp.maxScore : 0 }}</td>
                <td>{{ projectExp.minScore ? projectExp.minScore : 0 }}</td>
                <td>{{ projectExp.avgScore ? projectExp.avgScore : 0 }}</td>
                <td>{{ projectExp.six_percent ? projectExp.six_percent : 0 }}% </td>
                <td>{{ projectExp.relativeDev ? projectExp.relativeDev : 0 }}%</td>
              </tr>
              <tr> 
                <td>百分比</td>
                <td>{{ projectExp.ten_nine_percent ? projectExp.ten_nine_percent : 0  }}%</td>
                <td>{{ projectExp.nine_eight_percent ? projectExp.nine_eight_percent : 0  }}%</td>
                <td>{{ projectExp.eight_seven_percent ? projectExp.eight_seven_percent : 0  }}%</td>
                <td>{{ projectExp.seven_six_percent ? projectExp.seven_six_percent : 0  }}%</td>  
                <td>{{ projectExp.zero_six_percent ? projectExp.zero_six_percent : 0  }}%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
        </div>
        <!-- 底部柱状图 -->
        <div id="projectExp"></div>
      </div>
      <div v-show="projectShow === '3'" style="width: 100%;">
          <!-- 顶部表格 -->
          <div class="TopTable">
            <table>
              <caption style="margin-bottom: 0.521vw;" align="top">测试成绩分布</caption> 
              <tr>
                <td>分数段</td>
                <td>[90,100]</td>
                <td>[80,90)</td>
                <td>[70,80)</td>
                <td>[60,70)</td>
                <td>[0,60)</td>
                <td>最高分</td>
                <td>最低分</td>
                <td>平均分</td>
                <td>及格率</td>
                <td>相对偏差</td>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ projectTest.ten_nine_number ? projectTest.ten_nine_number : 0 }}</td>
                <td>{{ projectTest.nine_eight_number ? projectTest.nine_eight_number : 0 }}</td>
                <td>{{ projectTest.eight_seven_number ? projectTest.eight_seven_number : 0 }}</td>
                <td>{{ projectTest.seven_six_number ? projectTest.seven_six_number : 0 }}</td>
                <td>{{ projectTest.zero_six_number ? projectTest.zero_six_number : 0 }}</td>
                <td>{{ projectTest.maxScore ? projectTest.maxScore : 0 }}</td>
                <td>{{ projectTest.minScore ? projectTest.minScore : 0 }}</td>
                <td>{{ projectTest.avgScore ? projectTest.avgScore : 0 }}</td>
                <td>{{ projectTest.six_percent ? projectTest.six_percent : 0 }}% </td>
                <td>{{ projectTest.relativeDev ? projectTest.relativeDev : 0 }}%</td>
              </tr>
              <tr> 
                <td>百分比</td>
                <td>{{ projectTest.ten_nine_percent ? projectTest.ten_nine_percent : 0  }}%</td>
                <td>{{ projectTest.nine_eight_percent ? projectTest.nine_eight_percent : 0  }}%</td>
                <td>{{ projectTest.eight_seven_percent ? projectTest.eight_seven_percent : 0  }}%</td>
                <td>{{ projectTest.seven_six_percent ? projectTest.seven_six_percent : 0  }}%</td>  
                <td>{{ projectTest.zero_six_percent ? projectTest.zero_six_percent : 0  }}%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
        </div>
        <!-- 底部柱状图 -->
        <div id="projectTest"></div>
      </div>
      <div v-show="projectShow === '4'" style="width: 100%;">
          <!-- 顶部表格 -->
          <div class="TopTable">
            <table>
              <caption style="margin-bottom: 0.521vw;" align="top">操作成绩分布</caption> 
              <tr>
                <td>分数段</td>
                <td>[90,100]</td>
                <td>[80,90)</td>
                <td>[70,80)</td>
                <td>[60,70)</td>
                <td>[0,60)</td>
                <td>最高分</td>
                <td>最低分</td>
                <td>平均分</td>
                <td>及格率</td>
                <td>相对偏差</td>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ projectOpt.ten_nine_number ? projectOpt.ten_nine_number : 0 }}</td>
                <td>{{ projectOpt.nine_eight_number ? projectOpt.nine_eight_number : 0 }}</td>
                <td>{{ projectOpt.eight_seven_number ? projectOpt.eight_seven_number : 0 }}</td>
                <td>{{ projectOpt.seven_six_number ? projectOpt.seven_six_number : 0 }}</td>
                <td>{{ projectOpt.zero_six_number ? projectOpt.zero_six_number : 0 }}</td>
                <td>{{ projectOpt.maxScore ? projectOpt.maxScore : 0 }}</td>
                <td>{{ projectOpt.minScore ? projectOpt.minScore : 0 }}</td>
                <td>{{ projectOpt.avgScore ? projectOpt.avgScore : 0 }}</td>
                <td>{{ projectOpt.six_percent ? projectOpt.six_percent : 0 }}% </td>
                <td>{{ projectOpt.relativeDev ? projectOpt.relativeDev : 0 }}%</td>
              </tr>
              <tr> 
                <td>百分比</td>
                <td>{{ projectOpt.ten_nine_percent ? projectOpt.ten_nine_percent : 0  }}%</td>
                <td>{{ projectOpt.nine_eight_percent ? projectOpt.nine_eight_percent : 0  }}%</td>
                <td>{{ projectOpt.eight_seven_percent ? projectOpt.eight_seven_percent : 0  }}%</td>
                <td>{{ projectOpt.seven_six_percent ? projectOpt.seven_six_percent : 0  }}%</td>  
                <td>{{ projectOpt.zero_six_percent ? projectOpt.zero_six_percent : 0  }}%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
        </div>
        <!-- 底部柱状图 -->
        <div id="projectOpt"></div>
      </div>
      <div v-show="projectShow === '5'" style="width: 100%;">
          <!-- 顶部表格 -->
          <div class="TopTable">
            <table>
              <caption style="margin-bottom: 0.521vw;" align="top">报告成绩分布</caption> 
              <tr>
                <td>分数段</td>
                <td>[90,100]</td>
                <td>[80,90)</td>
                <td>[70,80)</td>
                <td>[60,70)</td>
                <td>[0,60)</td>
                <td>最高分</td>
                <td>最低分</td>
                <td>平均分</td>
                <td>及格率</td>
                <td>相对偏差</td>
              </tr>
              <tr>
                <td>人数</td>
                <td>{{ projectReport.ten_nine_number ? projectReport.ten_nine_number : 0 }}</td>
                <td>{{ projectReport.nine_eight_number ? projectReport.nine_eight_number : 0 }}</td>
                <td>{{ projectReport.eight_seven_number ? projectReport.eight_seven_number : 0 }}</td>
                <td>{{ projectReport.seven_six_number ? projectReport.seven_six_number : 0 }}</td>
                <td>{{ projectReport.zero_six_number ? projectReport.zero_six_number : 0 }}</td>
                <td>{{ projectReport.maxScore ? projectReport.maxScore : 0 }}</td>
                <td>{{ projectReport.minScore ? projectReport.minScore : 0 }}</td>
                <td>{{ projectReport.avgScore ? projectReport.avgScore : 0 }}</td>
                <td>{{ projectReport.six_percent ? projectReport.six_percent : 0 }}% </td>
                <td>{{ projectReport.relativeDev ? projectReport.relativeDev : 0 }}%</td>
              </tr>
              <tr> 
                <td>百分比</td>
                <td>{{ projectReport.ten_nine_percent ? projectReport.ten_nine_percent : 0  }}%</td>
                <td>{{ projectReport.nine_eight_percent ? projectReport.nine_eight_percent : 0  }}%</td>
                <td>{{ projectReport.eight_seven_percent ? projectReport.eight_seven_percent : 0  }}%</td>
                <td>{{ projectReport.seven_six_percent ? projectReport.seven_six_percent : 0  }}%</td>  
                <td>{{ projectReport.zero_six_percent ? projectReport.zero_six_percent : 0  }}%</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          </table>
        </div>
        <!-- 底部柱状图 -->
        <div id="projectReport"></div>
      </div>
      <!-- <span>这是一段信息</span> -->
      <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button @click="projectCourseDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="projectCourseDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" >
@import "@/assets/css/teacher/project/projectnoScoped.scss";
.report .el-dialog__body{
  height: 65vh !important;;
  overflow: auto !important;;
}
.dialog-report-duibi .el-dialog__wrapper .el-dialog{
  margin-top: 5vh !important;
}
.layout .el-dialog__body {
    padding: 0px 20px !important;
}
.table-div .el-input.is-disabled .el-input__inner
{
  color: black !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/project/projectList.scss";
</style>

<script>
import projectList from "@/assets/js/teacher/project/projectList.js";
export default {
  ...projectList
};
</script>